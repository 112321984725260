import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer id="foot">
                <b>
                    © 2020 echooff
                </b>
                <br />
                <a href="http://gumab.pl">crawling test</a>
            </footer>
        );
    }
}

export default Footer;